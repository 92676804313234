// src/store/FormProvider.js
import React, { useState } from "react";
import FormContext from "./form-context";

function FormProvider({ children }) {
  const defaultDataState = {
    Fullname: "",
    DisplayName: "",
    Goals: [],
    Categories: [],
    Habits: "",
    Feedback: "",
    Calendars: [],
  };
  
  const defaultValidationState = {
    Fullname: false,
    DisplayName: false,
    Goals: false,
    Categories: false,
    Habits: false,
    Feedback: false,
    Google: false,
    File: false,
  };
  
  const [page, setPage] = useState(1);
  const [finalData, setFinalData] = useState([]);
  const [formData, setFormData] = useState(defaultDataState);
  const [validation, setValidation] = useState(defaultValidationState);
  const [calendars, setCalendars] = useState([]);
  const [isGoogleSynced, setIsGoogleSynced] = useState(false);
  const [skipSecondPage, setSkipSecondPage] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const validate = (a) => Object.values(a).reduce((sum, next) => sum && next, true);

  const resetDataState = () => {
    setFormData(defaultDataState);
    setValidation(defaultValidationState);
  };

  const setCurrentPage = (val) => setPage(val);

  const initialValue = {
    formData,
    page,
    numOfPages: 4,
    finalData,
    validation,
    calendars,
    isGoogleSynced,
    skipSecondPage,
    isFileUploaded,
    setCurrentPage,
    setFormData,
    resetDataState,
    setFinalData,
    setValidation,
    setCalendars,
    setIsGoogleSynced,
    setSkipSecondPage,
    setIsFileUploaded,
    validate,
  };

  return (
    <FormContext.Provider value={initialValue}>{children}</FormContext.Provider>
  );
}

export default FormProvider;

// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBqllSbjacYV2vHyDdKr_G6rwzBjvZD_qA",
  authDomain: "captime-397ba.firebaseapp.com",
  databaseURL: "https://captime-397ba-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "captime-397ba",
  storageBucket: "captime-397ba.appspot.com",
  messagingSenderId: "841301772128",
  appId: "1:841301772128:web:ba4b7bf37e8e005e048005",
  measurementId: "G-KB8LDL2K5L"
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const saveFormData = async (data) => {
  try {
    const docRef = await addDoc(collection(db, 'formData'), data);
    console.log('Document written with ID: ', docRef.id);
  } catch (e) {
    console.error('Error adding document: ', e);
  }
};

export { db };
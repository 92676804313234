// src/theme.js
import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  colors: {
    orange: {
      400: "#FF5001",
    },
  },
});

export default customTheme;

// src/App.js
import React from "react";
import "./App.css";
import LogoHeader from "./components/LogoHeader";
import LandingFormPage from "./components/LandingFormPage";
import FormProvider from "./store/FormProvider";
import { ChakraProvider } from "@chakra-ui/react";
import customTheme from "./theme";

function App() {
  return (
    <ChakraProvider theme={customTheme}>
      <FormProvider>
        <div className="app">
          <LogoHeader />
          <LandingFormPage />
        </div>
      </FormProvider>
    </ChakraProvider>
  );
}

export default App;

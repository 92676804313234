import React from 'react';
import Captime from "../images/Captime-02.png";
import classes from "./css/LogoHeader.module.css";

function LogoHeader() {
  return (
    <div className={classes.logoBar}>
      <img className={classes.logo} src={Captime} alt="Logo" />
    </div>
  );
}

export default LogoHeader;

import React, { useContext } from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { Stepper, Step, StepIndicator, StepStatus, StepTitle, StepDescription, StepSeparator, StepNumber, StepIcon } from '@chakra-ui/react';
import FormContext from '../store/form-context';

function PaginateBar() {
  const steps = [
    { title: 'Sync', description: 'Details' },
    { title: 'Select', description: 'Calendars' },
    { title: 'Fill out', description: 'Questions' },
    { title: 'Finished', description: 'Get your Report' },
  ];

  const { setCurrentPage, page, numOfPages, validate, validation } = useContext(FormContext);

  const handleStepChange = (index) => {
    if (index === numOfPages - 1 && !validate(validation)) {
      return;
    }
    setCurrentPage(index + 1);
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  if (isMobile) {
    return null; // Do not render on mobile devices
  }

  return (
    <Box marginTop={"60px"} marginBottom={"30px"} width={"60rem"}>
      <Stepper size='lg' index={page - 1} colorScheme='orange'>
        {steps.map((step, index) => (
          <Step key={index} onClick={() => handleStepChange(index)}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink='0'>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

export default PaginateBar;

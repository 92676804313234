// src/components/UsageForm.js
import React, { useContext, useState } from "react";
import {
  Box,
  Heading,
  Text,
  Stack,
  Link,
  Input,
  Select,
  Button,
  Tag,
  TagLabel,
  TagCloseButton,
  FormControl,
  FormLabel,
  Flex,
  RadioGroup,
  Radio,
  Textarea
} from '@chakra-ui/react';
import ReactSelect from 'react-select';
import FormContext from "../store/form-context";

const categoryOptions = [
  { value: 'work', label: '🖥️ Work' },
  { value: 'private', label: '🏠 Private' },
  { value: 'family', label: '👪 Family' },
  { value: 'friends', label: '👫 Friends' },
  { value: 'hobbies', label: '🎨 Hobbies' },
  { value: 'travel', label: '✈️ Travel' },
  { value: 'education', label: '📚 Education' },
  { value: 'business', label: '💼 Business' },
  { value: 'sports', label: '🏅 Sports' },
  { value: 'entertainment', label: '🎬 Entertainment' },  
  { value: 'personal-development', label: '🌱 Personal Development' },
  { value: 'health', label: '💪 Health' },
  { value: 'finance', label: '💰 Finance' },
  { value: 'technology', label: '💻 Technology' },
  { value: 'volunteering', label: '🤝 Volunteering' },
  { value: 'events', label: '📅 Events' },
  { value: 'shopping', label: '🛒 Shopping' },
  { value: 'social-media', label: '📱 Social Media' },
  { value: 'food', label: '🍔 Food' },
  { value: 'beauty', label: '💅 Beauty' },
  { value: 'nature', label: '🌳 Nature' },
  { value: 'pets', label: '🐾 Pets' },
  { value: 'household', label: '🏡 Household' },
  { value: 'religion', label: '⛪ Religion' },
  { value: 'community', label: '🏘️ Community' },
  { value: 'other', label: '🔖 Other' },
];


const customStyles = {
  control: (base) => ({
    ...base,
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: 'darkorange'
    },
    borderRadius: '5px',
    padding: '8px',
    fontSize: '16px',
  }),
  option: (base) => ({
    ...base,
    borderRadius: '15px',
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: '#FFEDD5', // Light orange background
    color: 'black',
    borderRadius: '15px',
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: 'black'
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: 'black',
    ':hover': {
      backgroundColor: 'FFEDD5',
      color: 'grey'
    }
  })
};

function GoalsForm() {
  const {  setFormData } = useContext(FormContext);
  const [goal, setGoal] = useState("");
  const [priority, setPriority] = useState("medium");
  const [goals, setGoals] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isBuildingHabits, setIsBuildingHabits] = useState('no');
  const [feedback, setFeedback] = useState("");

  const handleAddGoal = () => {
    if (goal.trim() !== "") {
      const newGoals = [...goals, { goal, priority }];
      setGoals(newGoals);
      setFormData((prevData) => ({ ...prevData, Goals: newGoals }));
      setGoal("");
      setPriority("medium");
    }
  };

  const handleRemoveGoal = (index) => {
    const newGoals = goals.filter((_, i) => i !== index);
    setGoals(newGoals);
    setFormData((prevData) => ({ ...prevData, Goals: newGoals }));
  };

  const handleSelectChange = (selectedOptions) => {
    const categories = selectedOptions ? selectedOptions.map(option => option.label) : [];
    setSelectedCategories(selectedOptions || []);
    setFormData((prevData) => ({ ...prevData, Categories: categories }));
  };

  const handleHabitsChange = (value) => {
    const habits = value === 'yes';
    setIsBuildingHabits(value);
    setFormData((prevData) => ({ ...prevData, Habits: habits }));
  };

  const handleFeedbackChange = (e) => {
    const feedback = e.target.value;
    setFeedback(feedback);
    setFormData((prevData) => ({ ...prevData, Feedback: feedback }));
  };

  return (
    <Stack spacing={8} mx={'auto'} px={6} pt={10} maxW={'xl'}>
      <Stack align={'center'}>
        <Heading fontSize={'4xl'}>Help us understand!</Heading>
        <Text fontSize={'lg'} color={'gray.600'}>
          Tell us your goals and categories to get the <Link color={'orange.400'}>perfect report</Link> ✌️
        </Text>
      </Stack>
      <Box>
        <FormControl id="goal" mb={4}>
          <FormLabel>
            <Heading fontSize={'2xl'}>Goals</Heading>
            <Text fontSize={'md'} color={'gray.600'}>Helps us tailor your experience.
</Text>
          </FormLabel>
          <Flex align="center">
            <Input
              placeholder="Enter your goal"
              focusBorderColor="orange.400"
              value={goal}
              onChange={(e) => setGoal(e.target.value)}
              mr={2}
              flex="1"
            />
            <Select value={priority} onChange={(e) => setPriority(e.target.value)} mr={2} flex="0.4">
              <option value="high">High</option>
              <option value="medium">Medium</option>
              <option value="low">Low</option>
            </Select>
            <Button colorScheme="orange" onClick={handleAddGoal} flex="0.3">Add Goal</Button>
          </Flex>
        </FormControl>
        <Box mt={4}>
          {goals.map((item, index) => (
            <Tag
              size="lg"
              key={index}
              borderRadius="full"
              variant="solid"
              colorScheme={item.priority === "high" ? "red" : item.priority === "medium" ? "yellow" : "green"}
              m={1}
            >
              <TagLabel>{item.goal} - {item.priority.charAt(0).toUpperCase() + item.priority.slice(1)}</TagLabel>
              <TagCloseButton onClick={() => handleRemoveGoal(index)} />
            </Tag>
          ))}
        </Box>
      </Box>
      <Box>
        <FormControl id="categories" mb={4}>
          <FormLabel>
            <Heading fontSize={'2xl'}>Categories</Heading>
            <Text fontSize={'md'} color={'gray.600'}>Which categories best match your calendar?</Text>
          </FormLabel>
          <ReactSelect
            isMulti
            borderColor="orange.400"
            ocusBorderColor="orange.400"
            name="categories"
            options={categoryOptions}
            value={selectedCategories}
            onChange={handleSelectChange}
            placeholder="Select categories..."
            styles={customStyles}
          />
        </FormControl>
      </Box>
      <Box>
        <FormControl id="building-habits" mb={4}>
          <FormLabel mb="0" mr={4}>
            <Heading fontSize={'2xl'}>Habits</Heading>
          </FormLabel>
          <Flex align="center">
            <Text fontSize={'md'} mr={4}>Are you currently trying to build habits/routines?</Text>
            <RadioGroup onChange={handleHabitsChange} value={isBuildingHabits}>
              <Stack direction="row">
                <Radio value="yes" colorScheme="orange">Yes</Radio>
                <Radio value="no" colorScheme="orange">No</Radio>
              </Stack>
            </RadioGroup>
          </Flex>
        </FormControl>
      </Box>
      <Box>
        <FormControl id="feedback" mb={4}>
          <FormLabel>
            <Heading fontSize={'2xl'}>Feedback</Heading>
            <Text fontSize={'md'} color={'gray.600'}>
              Let us know what you would like this time report to help you with, include, or provide feedback on.
            </Text>
          </FormLabel>
          <Textarea
            placeholder="Enter your feedback here..."
            value={feedback}
              focusBorderColor="orange.400"
            onChange={handleFeedbackChange}
            size="lg"
          />
        </FormControl>
      </Box>
    </Stack>
  );
}

export default GoalsForm;

// src/components/LandingFormPage.js
import React, { useContext, useEffect, useState } from "react";
import PaginateBar from "./PaginateBar";
import classes from "./css/LandingFormPage.module.css";
import UserNameForm from "./UserNameForm";
import CalendarSync from "./CalendarSync";
import UsageForm from "./UsageForm";
import CongratzPage from "./CongratzPage";
import FormContext from "../store/form-context";
import { NavigateNextOutlined } from "@mui/icons-material";
import { IconButton } from '@chakra-ui/react';
import { saveFormData } from "../firebase";

function LandingFormPage() {
  const {
    page,
    setCurrentPage,
    numOfPages,
    formData,
    setFinalData,
    resetDataState,
    isGoogleSynced,
    isFileUploaded,
  } = useContext(FormContext);

  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

  useEffect(() => {
    setIsNextButtonDisabled(!(isGoogleSynced || isFileUploaded));
  }, [isGoogleSynced, isFileUploaded]);

  const pageSet = () => {
    if (page < numOfPages - 1) {
      setCurrentPage(page + 1);
    } else if (page === numOfPages - 1) {
      setCurrentPage(page + 1);
      setIsNextButtonDisabled(true);
      saveFormData(formData);
    } else if (page === numOfPages) {
      setFinalData((current) => [...current, formData]);
      
      resetDataState();
      setCurrentPage(1);
    }
  };

  const handleValidationChange = (isValid) => {
    setIsNextButtonDisabled(!isValid);
  };

  return (
    <div className={classes.form}>
      <PaginateBar />
      <div className="card">
        {page === 1 && <UserNameForm />}
        {page === 2 && <CalendarSync onValidationChange={handleValidationChange} />}
        {page === 3 && <UsageForm />}
        {page === 4 && <CongratzPage />}
      </div>
      <IconButton
        isRound={true}
        variant="solid"
        aria-label="Done"
        fontSize="50px"
        onClick={pageSet}
        icon={<NavigateNextOutlined />}
        isDisabled={isNextButtonDisabled}
      />
    </div>
  );
}

export default LandingFormPage;

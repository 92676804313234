// src/components/CalendarSync.js
import React, { useContext, useState, useEffect } from "react";
import {
  Stack,
  Heading,
  Box,
  Text,
  Link,
  Select,
  Switch,
  useBreakpointValue,
  SimpleGrid,
  FormControl,
  FormLabel,
  Flex,
} from '@chakra-ui/react';
import FormContext from "../store/form-context";

function CalendarSync({ onValidationChange }) {
  const { setFormData, calendars, setCalendars } = useContext(FormContext);
  const [categories, setCategories] = useState({});
  const [checkedItems, setCheckedItems] = useState({});

  const handleCategoryChange = (calendarId, category) => {
    setCategories(prevCategories => ({ ...prevCategories, [calendarId]: category }));
    const updatedCalendars = calendars.map(calendar =>
      calendar.id === calendarId ? { ...calendar, category } : calendar
    );
    setCalendars(updatedCalendars);
    setFormData((prevData) => ({ ...prevData, Calendars: updatedCalendars }));
  };

  const handleCheckboxChange = (calendarId, isChecked) => {
    setCheckedItems(prevCheckedItems => ({ ...prevCheckedItems, [calendarId]: isChecked }));
    if (isChecked && !categories[calendarId]) {
      handleCategoryChange(calendarId, 'other');
    }
  };

  useEffect(() => {
    const isAnyCalendarChecked = Object.values(checkedItems).some(isChecked => isChecked);
    onValidationChange(isAnyCalendarChecked);
    const selectedCalendars = calendars.filter(calendar => checkedItems[calendar.id]);
    setFormData((prevData) => ({ ...prevData, Calendars: selectedCalendars }));
  }, [checkedItems, onValidationChange, calendars, setFormData]);

  const columns = useBreakpointValue({ base: 1, sm: Math.min(calendars.length, 2), md: Math.min(calendars.length, 3) });

  const headingFontSize = useBreakpointValue({ base: '2xl', md: '4xl' });
  const textFontSize = useBreakpointValue({ base: 'sm', md: 'lg' });
  const boxWidth = useBreakpointValue({ base: '100%', sm: '300px', md: '300px' });

  return (
    <Flex align={'center'} justify={'center'} p={4} bg="transparent">
      <Stack spacing={8} align="center" w="full">
        <Stack align={'center'}>
          <Heading fontSize={headingFontSize}>Select the calendars you want to analyze!</Heading>
          <Text fontSize={textFontSize} color={'gray.600'}>
            Let us know which calendar we should <Link color={'#FF5001'}>analyze</Link> ✌️
          </Text>
        </Stack>

        <SimpleGrid columns={columns} spacing={4} w="full">
          {calendars.map(calendar => (
            <Box
              key={calendar.id}
              p={4}
              borderWidth={1}
              borderRadius="lg"
              overflow="hidden"
              bg="rgb(260, 248, 248)"
              boxShadow="md"
              display="flex"
              flexDirection="column"
              alignItems="center"
              w={boxWidth}
              m="auto"
            >
              <Text fontWeight="bold" mb={2}>{calendar.name}</Text>
              <Text mb={2}>Number of Events: {calendar.eventCount}</Text>
              
              <Select
                placeholder="Select category"
                value={categories[calendar.id] || "other"}
                onChange={(e) => handleCategoryChange(calendar.id, e.target.value)}
                isDisabled={!checkedItems[calendar.id]}
                mt={2}
                maxW="200px"
                focusBorderColor="#FF5001"
              >
                <option value="work">Work</option>
                <option value="private">Private</option>
                <option value="family">Family</option>
                <option value="friends">Friends</option>
                <option value="hobbies">Hobbies</option>
                <option value="travel">Travel</option>
                <option value="education">Education</option>
                <option value="business">Business</option>
                <option value="sports">Sports</option>
                <option value="entertainment">Entertainment</option>
                <option value="personal-development">Personal Development</option>
                <option value="other">Other</option>
              </Select>
              <FormControl display="flex" alignContent={"center"} alignItems={"center"} justifyContent={"center"} mb={2} pt={2}>
                <FormLabel htmlFor={`switch-${calendar.id}`} mb="0">
                  Select
                </FormLabel>
                <Switch
                  id={`switch-${calendar.id}`}
                  isChecked={checkedItems[calendar.id] || false}
                  onChange={(e) => handleCheckboxChange(calendar.id, e.target.checked)}
                  colorScheme="orange"
                />
              </FormControl>
            </Box>
          ))}
        </SimpleGrid>
      </Stack>
    </Flex>
  );
}

export default CalendarSync;

import React, { useContext, useState, useEffect } from "react";
import { useDropzone } from 'react-dropzone';
import { FaApple, FaGoogle } from "react-icons/fa";
import FormContext from "../store/form-context";
import { gapi } from 'gapi-script';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Divider,
  AbsoluteCenter,
  Button,
  Heading,
  Text,
  Stack,
  Link,
  VStack,
  CloseButton,
  useBreakpointValue,
} from '@chakra-ui/react';
import ICAL from 'ical.js';

function UserNameForm() {
  const { setFormData, formData, setValidation, setIsGoogleSynced, setCalendars, setSkipSecondPage, setIsFileUploaded } = useContext(FormContext);
  const [isGoogleSyncedLocal, setIsGoogleSyncedLocal] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");

  const fullNameHandler = (event) => {
    const value = event.target.value;
    setFormData((prevData) => ({ ...prevData, Fullname: value }));
    value === ""
      ? setValidation((prevData) => ({ ...prevData, Fullname: false }))
      : setValidation((prevData) => ({ ...prevData, Fullname: true }));
  };

  const displayNameHandler = (event) => {
    const value = event.target.value;
    setFormData((prevData) => ({ ...prevData, DisplayName: value }));
    value === ""
      ? setValidation((prevData) => ({ ...prevData, DisplayName: false }))
      : setValidation((prevData) => ({ ...prevData, DisplayName: true }));
  };

  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  const API_KEY = process.env.REACT_APP_API_KEY;
  const DISCOVERY_DOCS = [process.env.REACT_APP_DISCOVERY_DOCS];
  const SCOPES = process.env.REACT_APP_SCOPES;

  useEffect(() => {
    function start() {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      });
    }

    gapi.load("client:auth2", start);
  }, [DISCOVERY_DOCS]);

  const handleGoogleSync = () => {
    gapi.auth2.getAuthInstance().signIn().then(() => {
      gapi.client.calendar.calendarList.list().then(response => {
        const calendarList = response.result.items;
        const calendarsWithEvents = [];

        const now = new Date();
        const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());

        const fetchEventsPromises = calendarList.map(calendar => {
          return gapi.client.calendar.events.list({
            'calendarId': calendar.id,
            'timeMin': lastMonth.toISOString(),
            'timeMax': now.toISOString(),
            'showDeleted': false,
            'singleEvents': true,
            'maxResults': 2500, // Maximum allowed value
            'orderBy': 'startTime',
          }).then(response => {
            calendarsWithEvents.push({
              name: calendar.summary,
              id: calendar.id,
              events: response.result.items,
              eventCount: response.result.items.length
            });
          });
        });

        setValidation((prevData) => ({ ...prevData, Google: true }));

        Promise.all(fetchEventsPromises).then(() => {
          setCalendars(calendarsWithEvents);
          setIsGoogleSynced(true);
          setIsGoogleSyncedLocal(true);
        });
      });
    });
  };

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && file.name.endsWith('.ics')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        // Parse the uploaded calendar data using ical.js
        const jcalData = ICAL.parse(text);
        const comp = new ICAL.Component(jcalData);
        const events = comp.getAllSubcomponents('vevent').map(vevent => {
          const event = new ICAL.Event(vevent);
          return {
            summary: event.summary,
            description: event.description,
            start: event.startDate.toJSDate(),
            end: event.endDate.toJSDate(),
          };
        });
        const calendarsWithEvents = [
          {
            name: "Uploaded Calendar",
            id: "uploaded-calendar",
            events: events,
            eventCount: events.length
          }
        ];
        setCalendars(calendarsWithEvents);
        setSkipSecondPage(true);  // Set flag to skip second page
        setUploadedFileName(file.name); // Set the uploaded file name
        setIsFileUploaded(true);
        setValidation((prevData) => ({ ...prevData, File: true }));
      };
      reader.readAsText(file);
    } else {
      alert('Please select a valid .ics file');
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: '.ics',
    maxFiles: 1,
  });

  const handleRemoveFile = () => {
    setUploadedFileName("");
    setCalendars([]);
    setSkipSecondPage(false);
    setIsFileUploaded(false);
    setValidation((prevData) => ({ ...prevData, File: false }));
  };

  const headingFontSize = useBreakpointValue({ base: '2xl', md: '4xl' });
  const textFontSize = useBreakpointValue({ base: 'sm', md: 'lg' });
  const buttonSize = useBreakpointValue({ base: 'sm', md: 'md' });

  return (
    <Flex align={'center'} justify={'center'} p={4}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} w={'full'}>
        <Stack align={'center'}>
          <Heading fontSize={headingFontSize}>Sync your Calendar now!</Heading>
          <Text fontSize={textFontSize} color={'gray.600'}>
            to get an exclusive <Link color={'#FF5001'}>report</Link> ✌️
          </Text>
        </Stack>
        <Box p={4} w={'full'}>
          <VStack spacing={4} align="stretch">
            <FormControl id="name" isRequired>
              <FormLabel>Full Name</FormLabel>
              <Input
                type="text"
                value={formData.Fullname}
                onChange={fullNameHandler}
                focusBorderColor="#FF5001"
              />
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={formData.DisplayName}
                onChange={displayNameHandler}
                focusBorderColor="#FF5001"
              />
            </FormControl>
            <Box>
              <FormLabel>Upload .ics file</FormLabel>
              {!uploadedFileName ? (
                <Box
                  {...getRootProps()}
                  borderWidth={2}
                  borderRadius="md"
                  p={4}
                  textAlign="center"
                >
                  <input {...getInputProps({ accept: '.ics' })} />
                  <Text color="gray.600">Drag 'n' drop an .ics file here, or click to select one</Text>
                </Box>
              ) : (
                <Flex align="center">
                  <Text mr={2}>{uploadedFileName}</Text>
                  <CloseButton onClick={handleRemoveFile} />
                </Flex>
              )}
            </Box>
            <Box position="relative" padding="3">
              <Divider/>
              <AbsoluteCenter px="2" bg={"#faf8f8"}>
                Or 
              </AbsoluteCenter>
            </Box>
            <Button
              w={'full'}
              onClick={handleGoogleSync}
              variant={isGoogleSyncedLocal ? 'solid' : 'outline'}
              colorScheme={isGoogleSyncedLocal ? 'green' : '#FF5001'}
              leftIcon={<FaGoogle />}
              isDisabled={isGoogleSyncedLocal}
              size={buttonSize}
            >
              {isGoogleSyncedLocal ? 'Google Calendar Synced' : 'Sync Google Calendar'}
            </Button> 
            <Button
              w={'full'}
              variant={'outline'}
              leftIcon={<FaApple />}
              isDisabled={true}
              size={buttonSize}
            >
              Sync Coming Soon
            </Button>
          </VStack>
        </Box>
      </Stack>
    </Flex>
  );
}

export default UserNameForm;

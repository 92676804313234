import React, {  useState, useEffect } from "react";
import { Box, Heading, Text, Spinner } from '@chakra-ui/react';

function CongratzPage() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <Box textAlign="center" py={10} px={6}>
        <Spinner size="xl" color="orange.400" />
      </Box>
    );
  }

  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading as="h2" size="xl" mt={6} mb={2}>
        Thanks for trusting Captime!
      </Heading>
      <Text fontSize="lg" color="gray.600">
        Because of high demand, this could take longer - you’ll get your report within 48 hours via email.
      </Text>
    </Box>
  );
}

export default CongratzPage;
